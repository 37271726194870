/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { GooglePay } from "../google-pay";
import { ApplePay } from "../apple-pay";
import { useSearchParams } from "react-router-dom";
import { api_checkout_invoice_url } from "../constants";
import { errorNotification } from "../../notification-popup";
import { postGatewayPaymentMessage } from "../../constants/commonUtils";

export const GglApplePay = () => {
  const [featureData, setFeatureData] = useState({
    amount: "",
    currency: "",
    country_code:"",
    is_apple_pay: false,
    is_google_pay: false,
    is_support_3d: false,
  });
  const [isGooglePaying, setIsGooglePaying] = useState(false);
  const [isApplePaying, setIsApplePaying] = useState(false);
  const [searchParams] = useSearchParams();

  const handleCheckFeature = async () => {
    const apiUrl = api_checkout_invoice_url + "wpf/check-feature";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        c1f7U34t: searchParams.get("c1f7U34t"),
      }),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(response);
      }
      setFeatureData(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    handleCheckFeature();
  }, []);

  const handleGooglePay = async (isBol, body) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...body,
        c1f7U34t: searchParams.get("c1f7U34t"),
        is_support_3d: featureData.is_support_3d,
      }),
    };
    const apiUrl = api_checkout_invoice_url + "wpf/payment";
    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      if (!response.ok) {
        setIsGooglePaying(false);
        errorNotification(data?.message);
        throw new Error(response);
      }
      
      const d = data?.data || data;
      postGatewayPaymentMessage(d)
      window.location.href = d.url;

      setIsGooglePaying(false);
    } catch (error) {
      setIsGooglePaying(false);
    }
  };

  const handleApplePay = async (isBol, body) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...body,
        c1f7U34t: searchParams.get("c1f7U34t"),
        is_support_3d: featureData.is_support_3d,
      }),
    };
    const apiUrl = api_checkout_invoice_url + "wpf/payment";
    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      if (!response.ok) {
        setIsApplePaying(false);
        errorNotification(data?.message);
        throw new Error(response);
      }
      const d = data?.data || data;
      postGatewayPaymentMessage(d)
      window.location.href = d.url;

      setIsApplePaying(false);
    } catch (error) {
      setIsApplePaying(false);
    }
  };
  
  return (
    <div className="pay-buttons">
      <GooglePay
        values={{}}
        featureData={featureData}
        isLoading={isGooglePaying}
        setIsLoading={setIsGooglePaying}
        handleGooglePayClick={handleGooglePay}
        is_google_pay={featureData.is_google_pay}
        isGoogleApplePay={true}
      />
      <ApplePay
        values={{}}
        featureData={featureData}
        isLoading={isApplePaying}
        setIsLoading={setIsApplePaying}
        handleApplePayClick={handleApplePay}
        is_apple_pay={featureData.is_apple_pay}
        is_support_3d={featureData.is_support_3d}
        isGoogleApplePay={true}
      />
    </div>
  );
};
