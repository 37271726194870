export const postGatewayPaymentMessage = (d) => {
  try {
    window.top.postMessage(
      {
        source: "gateway-payment",
        status: d.status,
        merchant_orderid: d.merchant_orderid,
        trxID: d.tx_id,
      },
      "*"
    );
  } catch (ignore) {}
};
