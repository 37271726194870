import React from "react";
import axios from "axios";
import { api_checkout_invoice_url } from "./constants";
import { applePay_info } from "../config";

export function ApplePay({
  values,
  isLoading,
  featureData,
  is_apple_pay,
  setIsLoading,
  is_support_3d,
  handleApplePayClick,
  isGoogleApplePay,
}) {
  const isAppleDevice = /iPhone|iPad|iPod|Macintosh/.test(navigator.userAgent);
  const handlePayment = async () => {
    const methods = {
      supportedMethods: "https://apple.com/apple-pay",
      data: {
        version: 3,
        merchantIdentifier: is_support_3d
          ? featureData.mode === "TEST"
            ? applePay_info.test.merchantIdentifier3d
            : applePay_info.prod.merchantIdentifier3d
          : featureData.mode === "TEST"
          ? applePay_info.test.merchantIdentifier2d
          : applePay_info.prod.merchantIdentifier2d,
        merchantCapabilities: ["supports3DS"],
        supportedNetworks: ["amex", "discover", "masterCard", "visa"],
        countryCode: featureData?.country_code || "US",
      },
    };
    const details = {
      total: {
        label: applePay_info?.label || "Gateway Guardian",
        amount: {
          value: `${featureData?.amount}`,
          currency: `${featureData?.currency}`,
        },
      },
    };

    if (window.PaymentRequest) {
      try {
        setIsLoading(true);
        const request = new PaymentRequest([methods], details);

        const canPay = await request.canMakePayment();
        if (!canPay) {
          alert("Apple Pay is not available on this device.");
          return;
        }
        request.onmerchantvalidation = async (event) => {
          try {
            // Call your own server to request a new merchant session.
            const merchantSession = (
              await axios.post(
                `${api_checkout_invoice_url}/apple-pay/validate-merchant`,
                {
                  is_support_3d: is_support_3d,
                  mode: featureData.mode,
                }
              )
            ).data;
            event.complete(merchantSession);
          } catch (err) {
            setIsLoading(false);
            console.error("Error fetching merchant session", err);
            event.complete({ error: "Failed to fetch merchant session" });
          }
        };

        const paymentResponse = await request.show();
        // setData(JSON.stringify(paymentResponse));
        console.log(paymentResponse?.details?.token?.paymentData);
        const res = paymentResponse?.details?.token?.paymentData;

        const temp = {
          ...values,
          payment_method: "applepay",
          apple_pay_response: {
            ephemeralPublicKey: res?.header?.ephemeralPublicKey,
            publicKeyHash: res?.header?.publicKeyHash,
            transactionId: res?.header?.transactionId,
            signature: res?.signature,
            version: res?.version,
            data: res?.data,
          },
        };
        handleApplePayClick(false, temp);
        // Optionally update the state or handle the response

        // Complete the payment process
        await paymentResponse.complete("success");
      } catch (err) {
        console.error("Error during payment request", err);
        setIsLoading(false);
      }
    }
  };

  const handleClick = () => {
    if (!isGoogleApplePay) {
      // if (Object.values(values).includes("")) {
      //   handleApplePayClick?.(true);
      // } else {
      handlePayment();
      // }
    } else {
      handlePayment();
    }
  };
  return (
    <>
      {isAppleDevice && (
        <button
          type="button"
          onClick={handleClick}
          className="apple-pay-button"
          disabled={!is_apple_pay || isLoading}
          style={{
            pointerEvents: is_apple_pay && !isLoading ? "auto" : "none",
          }}
        >
          {isLoading ? (
            "loading..."
          ) : (
            <>
              <svg
                viewBox="0 0 384 512"
                xmlns="http://www.w3.org/2000/svg"
                className="main-header__logo-image"
              >
                <path
                  d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                  fill="#ffffff"
                />
              </svg>{" "}
              <span style={{ marginLeft: "3px" }}>Pay</span>
            </>
          )}
        </button>
      )}
    </>
  );
}
