export const googlePay_merchantInfo = {
  test: {
    worldpay_gateway: process.env.REACT_APP_WORLDPAY_GATEWAY_TEST,
    emerchantpay_gateway: process.env.REACT_APP_EMERCHANTPAY_GATEWAY_TEST,

    gatewayWorldpayMerchantId2D:
      process.env.REACT_APP_GATEWAY_WORLDPAY_MERCHANT_ID_2D_TEST,
    gatewayWorldpayMerchantId3D:
      process.env.REACT_APP_GATEWAY_WORLDPAY_MERCHANT_ID_3D_TEST,

    gatewayEmerchantpayMerchantId:
      process.env.REACT_APP_GATEWAY_EMERCHANTPAY_MERCHANT_ID_TEST,

    merchantIdWorldpay3D:
      process.env.REACT_APP_GOOGLE_MERCHANT_ID_WORLDPAY_3D_TEST,
    merchantIdWorldpay2D:
      process.env.REACT_APP_GOOGLE_MERCHANT_ID_WORLDPAY_2D_TEST,
    merchantIdEmerchantpay:
      process.env.REACT_APP_GOOGLE_MERCHANT_ID_EMERCHANTPAY_TEST,

    merchantNameWorldpay3D:
      process.env.REACT_APP_GOOGLE_MERCHANT_NAME_WORLDPAY_3D_TEST,
    merchantNameWorldpay2D:
      process.env.REACT_APP_GOOGLE_MERCHANT_NAME_WORLDPAY_2D_TEST,
    merchantNameEmerchantpay:
      process.env.REACT_APP_GOOGLE_MERCHANT_NAME_EMERCHANTPAY_TEST,

    environment: process.env.REACT_APP_GOOGLE_ENVIRONMENT_TEST,
  },
  prod: {
    worldpay_gateway: process.env.REACT_APP_WORLDPAY_GATEWAY_PROD,
    emerchantpay_gateway: process.env.REACT_APP_EMERCHANTPAY_GATEWAY_PROD,

    gatewayWorldpayMerchantId2D:
      process.env.REACT_APP_GATEWAY_WORLDPAY_MERCHANT_ID_2D_PROD,
    gatewayWorldpayMerchantId3D:
      process.env.REACT_APP_GATEWAY_WORLDPAY_MERCHANT_ID_3D_PROD,

    gatewayEmerchantpayMerchantId:
      process.env.REACT_APP_GATEWAY_EMERCHANTPAY_MERCHANT_ID_PROD,

    merchantIdWorldpay3D:
      process.env.REACT_APP_GOOGLE_MERCHANT_ID_WORLDPAY_3D_PROD,
    merchantIdWorldpay2D:
      process.env.REACT_APP_GOOGLE_MERCHANT_ID_WORLDPAY_2D_PROD,
    merchantIdEmerchantpay:
      process.env.REACT_APP_GOOGLE_MERCHANT_ID_EMERCHANTPAY_PROD,

    merchantNameWorldpay3D:
      process.env.REACT_APP_GOOGLE_MERCHANT_NAME_WORLDPAY_3D_PROD,
    merchantNameWorldpay2D:
      process.env.REACT_APP_GOOGLE_MERCHANT_NAME_WORLDPAY_2D_PROD,
    merchantNameEmerchantpay:
      process.env.REACT_APP_GOOGLE_MERCHANT_NAME_EMERCHANTPAY_PROD,

    environment: process.env.REACT_APP_GOOGLE_ENVIRONMENT_PROD,
  },
};

export const applePay_info = {
  test: {
    merchantIdentifier2d:
      process.env.REACT_APP_APPLE_MERCHANT_IDENTIFIER_2D_TEST,
    merchantIdentifier3d:
      process.env.REACT_APP_APPLE_MERCHANT_IDENTIFIER_3D_TEST,
  },
  prod: {
    merchantIdentifier2d:
      process.env.REACT_APP_APPLE_MERCHANT_IDENTIFIER_2D_PROD,
    merchantIdentifier3d:
      process.env.REACT_APP_APPLE_MERCHANT_IDENTIFIER_3D_PROD,
  },
};
